import { useQuery } from '@tanstack/react-query'
import { Flex, Spin, Typography } from 'antd'
import { FeedCard } from 'entities/feed'
import { userApi } from 'entities/user'
import { appApi } from 'shared/api/app.api'

const ProfileRewardsHistory = () => {

    const { data: userData } = useQuery({
        queryKey: ['userDataFetching'],
        queryFn: () => userApi.getUserData(),
        networkMode: 'offlineFirst'
    })

    const { data, isLoading } = useQuery({
        queryKey: ['feedQuery', userData?.userData.id],
        queryFn: () => appApi.rewards.listRewardsApiRewardsGet({
            rewarderId: userData?.userData.id
        }),
        enabled: userData?.userData.id !== undefined
    })

    console.log(data)
    return (
        <p>card</p>
        // <Flex gap="small" vertical>
        //     {
        //         isLoading
        //             ? <Spin />
        //             : data?.length
        //                 ? data && [...data].reverse().map(item => <FeedCard key={item.id} data={item} />)
        //                 : <Typography className="opacity50">No user rewards</Typography>
        //     }
        // </Flex>
    )
}

export { ProfileRewardsHistory }