import { useQuery } from '@tanstack/react-query'
import { Button, Flex, Tooltip, Typography } from 'antd'
import { userApi } from 'entities/user'
import { UserDeposit, UserDepositForm, UserWithdraw } from 'features/user'
import { useState } from 'react'
import { Price } from 'shared/ui/Price'

const ProfilePayments = () => {

    const { data } = useQuery({
        queryKey: ['userDataFetching'],
        queryFn: () => userApi.getUserData(),
        refetchInterval: 5000,
        networkMode: 'offlineFirst'
    })

    const [isOpen, setIsOpen] = useState(false)

    return (
        <Flex vertical gap="small">
            <Flex gap="small" align="center">
                <Typography>Balance: </Typography>
                <Price amount={(data?.sats?.free_sats || 0) + (data?.sats?.in_rewards || 0)} />
                /
                <Tooltip title="reserved">
                    <span className="opacity50">
                        <Price amount={data?.sats?.in_rewards} />
                    </span>
                </Tooltip>
            </Flex>
            <Flex gap="small">
                <Button type={isOpen ? 'primary' : 'default'} onClick={() => { setIsOpen(prev => !prev) }} size="small">Deposit</Button>
                <UserWithdraw />
            </Flex>
            {
                isOpen
                    ? <UserDepositForm />
                    : null
            }
            {/* HERE */}
        </Flex>
    )
}
export { ProfilePayments }