import { useQuery } from '@tanstack/react-query'
import { Flex, Spin } from 'antd'
import { FeedCard, useFeedStore } from 'entities/feed'
import { feedApi } from 'entities/feed/api/feed.api'
import { userApi } from 'entities/user'
import { FeedCheckPull } from 'features/feed'
import { GuideLabel } from 'shared/ui/GuideLabel'
import { useShallow } from 'zustand/react/shallow'

const FeedList = () => {

    const { pageSize, currentPage } = useFeedStore(useShallow(state => ({
        pageSize: state.pageSize,
        currentPage: state.currentPage
    })))

    const { data, isLoading } = useQuery({
        queryKey: feedApi.qkGetFeedList({
            skip: (currentPage - 1) * pageSize,
            limit: pageSize
        }),
        queryFn: () => feedApi.getFeedList({
            skip: (currentPage - 1) * pageSize,
            limit: pageSize
        }),
        refetchInterval: 5000
    })

    return (
        <Flex vertical gap="middle">
            {
                isLoading
                    ? <Spin />
                    : data && [...data]
                        .map((item, index) => <FeedCard
                            key={item.id}
                            data={item}
                            winnerBorder={item.winner_id === userApi.getUserId()}
                            guideSlot={index === 0 && <GuideLabel />}
                            pullSlot={<FeedCheckPull
                                owner_name={item.repository_data.full_name.split('/')[0]}
                                repo_name={item.repository_data.full_name.split('/')[1]}
                            />}
                        />
                        )
            }
        </Flex>
    )
}
export { FeedList }