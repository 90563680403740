import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Form, Input, notification, Popover } from 'antd'
import { FC } from 'react'
import { appApi } from 'shared/api/app.api'

const UserWithdraw: FC = () => {

    return (
        <Popover
            trigger={'click'}
            content={<WithdrawFor />}
            placement="bottom"
        >
            <Button size="small">Withdraw</Button>
        </Popover >
    )
}


const WithdrawFor: FC = () => {

    const { mutateAsync: createWithdraw, isPending } = useMutation({
        mutationFn: async (paymentRequest: string) => await appApi.wallets.withdrawSatsApiWalletWithdrawPost({
            requestBody: {
                invoice: paymentRequest
            }
        })
    })
    const [api, contextHolder] = notification.useNotification()
    const [form] = Form.useForm()

    return <>
        {contextHolder}
        <Form
            form={form}
            onFinish={async (e) => {
                try {
                    await createWithdraw(e.paymentRequest)
                    form.resetFields()
                } catch (e) {
                    api.error({
                        message: 'Error!'
                    })
                }
            }}
        >
            <Flex gap="small">
                <Form.Item
                    name="paymentRequest"
                    rules={[
                        {
                            required: true,
                            message: 'Required',
                        }
                    ]}
                >
                    <Input
                        placeholder="Payment request"
                    />
                </Form.Item>
                <Button loading={isPending} type="primary" htmlType="submit">Create</Button>
            </Flex>
        </Form>
    </>
}

export { UserWithdraw }