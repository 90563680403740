import { CopyOutlined, CheckOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Card, Col, Divider, Flex, Form, Input, notification, Popover, Row, Typography, Tooltip } from 'antd'
import { QRCodeCanvas } from 'qrcode.react'
import { useState } from 'react'
import { appApi } from 'shared/api/app.api'
import { Price } from 'shared/ui/Price'
import { DepositStatusChecker } from 'features/user/depositStatusChecker'

const UserDepositForm = () => {

    const { mutateAsync: createDeposit, isPending } = useMutation({
        mutationFn: async (amount: number) => await appApi.wallets.depositSatsApiWalletDepositPost({
            requestBody: {
                amount_sats: amount
            }
        })
    })

    const [builded, setBuilded] = useState<undefined | { amount: number, code: string, /* checkingId: string */ }>(undefined)
    const [api, contextHolder] = notification.useNotification()
    const [copied, setCopied] = useState(false)
    const [qrExpanded, setQrExpanded] = useState(false)


    const createDepositHandler = async (e: any) => {
        try {
            const resp = await createDeposit(e.amount)
            setBuilded({
                amount: e.amount,
                code: resp.invoice,
                // checkingId: resp.checking_id
            })
        } catch (e) {
            api.error({
                message: 'Error!'
            })
        }
    }

    return (
        <>
            {contextHolder}
            <Divider />
            <Row gutter={[12, 12]} style={{ width: '500px' }} align="middle">
                <Col span={12}>
                    <Form
                        onFinish={(e) => { createDepositHandler(e) }}
                        style={{ width: '100%' }}
                    >
                        <Flex vertical gap="small">
                            <Flex gap="small">
                                <Typography style={{ flexShrink: 0, marginTop: '4px' }} className="opacity50">Amount: </Typography>
                                <Form.Item
                                    name="amount"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Required',
                                        }
                                    ]}
                                >
                                    <Input placeholder="100 000" />
                                </Form.Item>
                            </Flex>
                            <Typography className="opacity50">max allowed: 100,000</Typography>
                            <Button
                                htmlType="submit"
                                style={{ marginTop: '20px' }}
                                loading={isPending}
                                type="primary">
                                {
                                    builded
                                        ? 'Refresh'
                                        : 'Generate'
                                }
                            </Button>
                        </Flex>
                    </Form>
                    {
                        builded
                            // ? <DepositStatusChecker checkingId={builded.checkingId} />
                            ? <></>
                            : <></>
                    }
                </Col>
                <Col span={12}>
                    <Flex vertical gap="small" align="center">
                        {
                            builded
                                ? <Flex align="center" gap="small">
                                    <Typography className="opacity50">Deposit:</Typography>
                                    <Price amount={builded?.amount} />
                                </Flex>
                                : <Typography
                                    style={{ textAlign: 'center' }}
                                    className="opacity50">Waiting for user to click generate...</Typography>
                        }

                        <Card size="small" bordered={false}>
                            {
                                builded
                                    ? <Tooltip placement="bottom" title={qrExpanded ? "Click to reset size": "Click to enlarge"}>
                                        <QRCodeCanvas 
                                            size={qrExpanded ? 400: 200} 
                                            value={"lightning:" + builded?.code} 
                                            includeMargin={true}
                                            onClick={() => setQrExpanded(!qrExpanded) }
                                            style={{ cursor: 'pointer' }}
                                        />
                                        </Tooltip>
                                    : <div style={{ width: 200, height: 200 }} />
                            }

                        </Card>
                        {
                            builded
                                ? <Flex align="center" gap="small">
                                    <Popover content={<Typography style={{ width: '200px' }}>{builded?.code}</Typography>}>
                                        <Typography style={{border: '1px solid white', padding: '5px'}} className="opacity50">{builded?.code.slice(0, 7) + '...'}</Typography>
                                    </Popover>
                                    <Button 
                                        onClick={() => {
                                            navigator.clipboard.writeText(builded?.code)
                                            setCopied(true)
                                            setTimeout(() => {
                                                setCopied(false)
                                            }, 2000)
                                        }} 
                                        icon={copied ? <CheckOutlined /> : <CopyOutlined />}
                                        >
                                        {copied ? 'Copied' : 'Copy'}
                                    </Button>
                                </Flex>
                                : <Typography></Typography>
                        }
                    </Flex>
                </Col>
            </Row>
            <Divider />
        </>
    )
}
export { UserDepositForm }