import { useQuery } from '@tanstack/react-query'
import { Flex, Pagination } from 'antd'
import { useFeedStore } from 'entities/feed'
import { feedApi } from 'entities/feed/api/feed.api'
import { CountResponse } from 'shared/gen'
import { useShallow } from 'zustand/react/shallow'

const FeedPagination = () => {

    const { pageSize, currentPage, setPage } = useFeedStore(
        useShallow(state => ({
            pageSize: state.pageSize,
            currentPage: state.currentPage,
            setPage: state.setPage
        }))
    )

    const { data } = useQuery({
        queryKey: feedApi.qkGetFeedTotalCount(),
        queryFn: () => feedApi.getFeedTotalCount(),
        refetchInterval: 5000
    })

    return (
        <Flex justify="center">
            <Pagination
                hideOnSinglePage={true}
                total={(data as CountResponse)?.count as number ?? 0}
                pageSize={pageSize}
                current={currentPage}
                showSizeChanger={false}
                onChange={(page) => setPage(page)}
            />
        </Flex>
    )
}
export { FeedPagination }